import React, { useState } from 'react';

const API_GATEWAY_URL = 'https://tgdfx7iuk0.execute-api.us-east-2.amazonaws.com/prod/signup';

const GetEarlyAccessButton = () => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setMessage('');

    try {
      const response = await fetch(API_GATEWAY_URL, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email }),
      });

      const data = await response.json();

      if (response.ok) {
        setMessage('Successfully signed up! Please check your email for more information.');
        setEmail('');
      } else {
        setMessage(data.message || 'An error occurred. Please try again.');
      }
    } catch (error) {
      console.error('Error:', error);
      setMessage('An error occurred. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="early-access-form mt-4 flex flex-col items-center">
      <form onSubmit={handleSubmit} className="flex flex-col items-center">
        <input
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="Enter your email"
          className="early-access-input"
          required
        />
        <button
          type="submit"
          className="early-access-button"
          disabled={isLoading}
        >
          {isLoading ? 'Signing up...' : 'Get Early Access'}
        </button>
      </form>
      {message && <p className="mt-2 text-secondary">{message}</p>}
    </div>
  );
};

export default GetEarlyAccessButton;

