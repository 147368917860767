import React from 'react';
import { motion } from 'framer-motion';
import { Terminal } from 'lucide-react';

const Header = () => (
  <motion.header
    initial={{ y: -50, opacity: 0 }}
    animate={{ y: 0, opacity: 1 }}
    transition={{ duration: 0.6, ease: 'easeOut' }}
    className="w-full bg-bg-primary bg-opacity-90 backdrop-filter backdrop-blur-lg py-4 px-8 fixed top-0 z-50 shadow-lg"
  >
    <div className="max-w-6xl mx-auto flex justify-between items-center">
      <a href="/" className="flex items-center" aria-label="Go to homepage">
        <Terminal size={28} className="text-accent-primary mr-3" />
        <span className="text-2xl font-extrabold text-text-primary tracking-tight">
          LowLevelAI
        </span>
      </a>
      <nav aria-label="Primary navigation">
        <ul className="flex space-x-8">
          <li>
            <a href="#home" className="text-text-secondary hover:text-accent-primary transition-colors duration-300 text-lg">
              Home
            </a>
          </li>
          <li>
            <a href="#features" className="text-text-secondary hover:text-accent-primary transition-colors duration-300 text-lg">
              Features
            </a>
          </li>
          <li>
            <a href="#why-oblivious-http" className="text-text-secondary hover:text-accent-primary transition-colors duration-300 text-lg">
              Why Oblivious HTTP
            </a>
          </li>
          <li>
            <a href="#pricing" className="text-text-secondary hover:text-accent-primary transition-colors duration-300 text-lg">
              Pricing
            </a>
          </li>
          <li>
            <a href="#faq" className="text-text-secondary hover:text-accent-primary transition-colors duration-300 text-lg">
              FAQ
            </a>
          </li>
        </ul>
      </nav>
    </div>
  </motion.header>
);

export default Header;
