import React from 'react';
import { motion } from 'framer-motion';
import { CheckCircle } from 'lucide-react';

const PricingTier = ({ name, price, features, highlighted = false }) => (
  <motion.div
    whileHover={{ scale: 1.05 }}
    className={`bg-bg-secondary p-10 rounded-lg shadow-xl transition-all duration-300 ${
      highlighted ? 'border-4 border-accent-primary' : ''
    }`}
  >
    <h3 className="text-3xl font-bold mb-6 text-text-primary tracking-tight">
      {name}
    </h3>
    <p className="text-3xl font-extrabold mb-6 text-accent-primary tracking-tight">
      {price === '0' ? 'Free' : price === 'Custom' ? price : `$${price}`}
      {price !== 'Custom' && (
        <span className="text-lg text-text-secondary font-medium">
          /month
        </span>
      )}
    </p>
    <ul className="mb-10 space-y-4">
      {features.map((feature, index) => (
        <li key={index} className="flex items-center text-lg">
          <CheckCircle
            size={24}
            className="text-accent-secondary mr-3 flex-shrink-0"
            aria-hidden="true"
          />
          <span className="text-text-secondary">{feature}</span>
        </li>
      ))}
    </ul>
    <motion.button
      whileHover={{ scale: 1.05 }}
      className={`w-full py-4 rounded-lg text-lg font-bold transition-all duration-300 ${
        highlighted
          ? 'bg-accent-primary text-bg-primary hover:bg-accent-secondary'
          : 'bg-bg-primary text-text-primary hover:bg-accent-secondary'
      }`}
    >
      Choose Plan
    </motion.button>
  </motion.div>
);

const PricingSection = () => (
  <section id="pricing" className="py-24 bg-bg-primary w-full">
    <div className="max-w-6xl mx-auto px-6">
      <h2 className="text-3xl font-extrabold text-center mb-16 text-accent-primary tracking-tight">
        Pricing Plans
      </h2>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-10">
        <PricingTier
          name="Starter"
          price="0"
          features={['100 queries per day', 'Basic code completion', 'Community support']}
        />
        <PricingTier
          name="Pro"
          price="10"
          features={[
            'Unlimited queries',
            'Advanced code generation',
            'Priority support',
            'Custom fine-tuning',
          ]}
          highlighted={true}
        />
        <PricingTier
          name="Enterprise"
          price="Custom"
          features={[
            'Unlimited queries',
            'Full feature set',
            'Dedicated support',
            'On-premises deployment',
            'Custom integrations',
          ]}
        />
      </div>
    </div>
  </section>
);

export default PricingSection;
