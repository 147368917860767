import React from 'react';
import { motion } from 'framer-motion';
import { Github, Twitter } from 'lucide-react';
import { FaDiscord } from 'react-icons/fa';

const Footer = () => (
  <footer className="bg-bg-secondary py-8 text-text-secondary w-full border-t border-opacity-10 border-text-secondary">
    <div className="max-w-6xl mx-auto px-6 flex flex-col md:flex-row justify-between items-center">
      <p className="text-sm">
        © {new Date().getFullYear()} LowLevelAI. All rights reserved.
      </p>
      <div className="flex space-x-4 mt-4 md:mt-0">
        <a href="/privacy-policy" className="text-sm hover:text-accent-primary">
          Privacy Policy
        </a>
        <a href="/terms-of-service" className="text-sm hover:text-accent-primary">
          Terms of Service
        </a>
      </div>
      <div className="flex space-x-6 mt-4 md:mt-0">
        <a
          href="https://github.com/7etsuo"
          target="_blank"
          rel="noopener noreferrer"
          className="text-text-secondary hover:text-accent-primary"
          aria-label="GitHub"
        >
          <Github size={24} aria-hidden="true" />
        </a>
        <a
          href="https://twitter.com/7etsuo"
          target="_blank"
          rel="noopener noreferrer"
          className="text-text-secondary hover:text-accent-primary"
          aria-label="Twitter"
        >
          <Twitter size={24} aria-hidden="true" />
        </a>
        <a
          href="https://discord.gg/c-asm"
          target="_blank"
          rel="noopener noreferrer"
          className="text-text-secondary hover:text-accent-primary"
          aria-label="Discord"
        >
          <FaDiscord size={24} aria-hidden="true" />
        </a>
      </div>
    </div>
  </footer>
);

export default Footer;
