import React, { useEffect, useState, useRef } from 'react';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { atomDark } from 'react-syntax-highlighter/dist/esm/styles/prism';

const TYPING_INTERVAL = 10;

const ExampleCode = `
\\send(to, from, count)
register short *to, *from;
register int count;
{
    register int n = (count + 7) / 8;
    switch (count % 8) {
    case 0: do { *to = *from++;
    case 7:      *to = *from++;
    case 6:      *to = *from++;
    case 5:      *to = *from++;
    case 4:      *to = *from++;
    case 3:      *to = *from++;
    case 2:      *to = *from++;
    case 1:      *to = *from++;
            } while (--n > 0);
    }
}
`;
const CodeTerminal = () => {
  const [displayedText, setDisplayedText] = useState('');
  const terminalRef = useRef(null);

  useEffect(() => {
    let i = 0;

    const typeNextChar = () => {
      if (i < ExampleCode.length) {
        setDisplayedText((prev) => prev + ExampleCode.charAt(i));
        if (terminalRef.current) {
          terminalRef.current.scrollTop = terminalRef.current.scrollHeight; // Auto-scroll to bottom
        }
        i++;
        setTimeout(typeNextChar, TYPING_INTERVAL);
      }
    };

    typeNextChar();
  }, []);

  return (
    <div ref={terminalRef} className="terminal-container p-6 rounded-lg shadow-xl bg-code-terminal-background">
      <div className="terminal-header bg-terminal-header p-2 rounded-t-lg flex items-center">
        <div className="w-3 h-3 bg-red-500 rounded-full mr-2"></div>
        <div className="w-3 h-3 bg-yellow-500 rounded-full mr-2"></div>
        <div className="w-3 h-3 bg-green-500 rounded-full"></div>
      </div>
      <div className="terminal-content p-4 text-code-text-color rounded-b-lg font-mono">
        <SyntaxHighlighter language="c" style={atomDark}>
          {displayedText}
        </SyntaxHighlighter>
      </div>
    </div>
  );
};

export default CodeTerminal;