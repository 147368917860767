import React from 'react';
import Header from './components/Header';
import HeroSection from './components/HeroSection';
import WhyObliviousHTTP from './components/WhyObliviousHTTP';
import FeatureSection from './components/FeatureSection';
import PricingSection from './components/PricingSection';
import FAQ from './components/FAQ';
import Footer from './components/Footer';

const ProfessionalDevLLMLandingPage = () => (
  <div className="bg-bg-primary min-h-screen text-text-primary flex flex-col">
    <Header />
    <main className="flex-grow">
      <HeroSection />
      <FeatureSection />
      <WhyObliviousHTTP />
      <PricingSection />
      <FAQ />
    </main>
    <Footer />
  </div>
);

export default ProfessionalDevLLMLandingPage;
