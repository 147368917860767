import React from 'react';
import { Terminal } from 'lucide-react';

const HeroTitle = () => (
  <div className="hero-title text-center max-w-lg mx-auto">
    <div className="flex flex-col items-center mb-6">
      <Terminal size={64} className="text-accent-primary mb-4" /> {/* Adjusted margin for vertical spacing */}
      <h1 className="text-4xl sm:text-5xl md:text-6xl font-extrabold text-text-primary leading-tight">
        AI for Low-Level Programming
      </h1>
    </div>
    <p className="text-base sm:text-lg text-text-secondary mb-6 leading-relaxed">
      A Fine-Tuned LLM for Developers, with Complete Anonymity via Oblivious HTTP.
    </p>
  </div>
);

export default HeroTitle;

