// ./src/components/FeatureSection.js
import React from 'react';
import { motion } from 'framer-motion';
import { Shield, Lock, CheckCircle } from 'lucide-react';

const Feature = ({ icon, title, description }) => (
  <motion.div
    whileHover={{ scale: 1.05 }}
    className="feature flex flex-col items-center text-center p-8 bg-bg-secondary rounded-lg shadow-lg hover:shadow-2xl transition-all duration-300"
  >
    <div className="mb-4 text-accent-primary" aria-label={title}>
      {React.cloneElement(icon, { size: 48 })}
    </div>
    <h3 className="text-2xl font-semibold mb-2 text-text-primary tracking-tight">
      {title}
    </h3>
    <p className="text-base text-text-secondary leading-relaxed">
      {description}
    </p>
  </motion.div>
);

const FeatureSection = () => (
  <section id="features" className="features-section py-24 bg-bg-primary w-full">
    <div className="max-w-6xl mx-auto px-6">
      <h2 className="text-4xl font-extrabold text-center mb-16 text-accent-primary tracking-tight">
        Key Features
      </h2>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
        <Feature
          icon={<Shield />}
          title="Anonymity"
          description="The server never knows the client's IP address or any identifying information."
        />
        <Feature
          icon={<Lock />}
          title="Security"
          description="The prompts and responses are encrypted, preventing any third-party from intercepting or altering the data."
        />
        <Feature
          icon={<CheckCircle />}
          title="Trust"
          description="This setup reduces the need for trust in the relay, as it cannot read the content of the requests or responses, only forwarding them."
        />
      </div>
    </div>
  </section>
);

export default FeatureSection;
