import React from 'react';
import { motion } from 'framer-motion';
import { Shield, Lock, Users, Zap } from 'lucide-react';

const ObliviousFeature = ({ Icon, title, description }) => (
  <motion.div
    whileHover={{ scale: 1.05 }}
    className="feature flex flex-col items-center text-center p-8 bg-bg-secondary rounded-lg shadow-lg hover:bg-opacity-80 transition-colors duration-300"
  >
    <div className="mb-6 text-accent-primary" aria-label={title}>
      <Icon size={48} />
    </div>
    <h3 className="text-2xl font-bold mb-4">{title}</h3>
    <p className="text-text-secondary text-lg">{description}</p>
  </motion.div>
);

const WhyObliviousHTTP = () => (
  <section id="why-oblivious-http" className="py-24 bg-bg-primary text-text-primary w-full">
    <div className="max-w-6xl mx-auto px-4">
      <h2 className="text-4xl font-bold text-center mb-12">Why Oblivious HTTP?</h2>
      <p className="text-text-secondary max-w-4xl mx-auto mb-12 text-center text-xl">
        <a href="https://www.rfc-editor.org/rfc/rfc9458" className="text-accent-secondary hover:text-accent-primary transition-colors duration-300">
          Oblivious HTTP (RFC 9458)
        </a> is a protocol designed to ensure complete anonymity and security for HTTP requests. By using Hybrid Public Key Encryption (HPKE) and a trusted relay, Oblivious HTTP allows you to interact with servers without revealing your IP address or linking multiple requests.
      </p>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-12">
        <ObliviousFeature
          Icon={Shield}
          title="Enhanced Anonymity"
          description="Oblivious HTTP ensures that your identity remains hidden. The server processes requests without knowing who you are, making it impossible to link different requests to the same client."
        />
        <ObliviousFeature
          Icon={Lock}
          title="Robust Security"
          description="By encrypting both requests and responses, Oblivious HTTP protects your data from being intercepted or altered by third parties. This level of security is critical for maintaining the integrity of sensitive information."
        />
        <ObliviousFeature
          Icon={Users}
          title="Trustworthy Architecture"
          description="The separation of roles between the relay and the gateway means that no single entity can compromise your privacy. The relay never sees your data, and the gateway never knows your identity."
        />
        <ObliviousFeature
          Icon={Zap}
          title="Performance and Privacy"
          description="Oblivious HTTP is designed to balance performance and privacy. It allows connection reuse for improved efficiency, without sacrificing the security and anonymity of your requests."
        />
      </div>
    </div>
  </section>
);

export default WhyObliviousHTTP;
