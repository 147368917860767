import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { ChevronDown } from 'lucide-react';

const FAQItem = ({ question, answer }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div
      className={`bg-bg-secondary p-6 rounded-lg cursor-pointer mb-6 shadow-lg ${
        isOpen ? 'ring-2 ring-accent-primary' : ''
      } transition-all duration-300`}
      onClick={() => setIsOpen(!isOpen)}
      aria-expanded={isOpen}
      aria-controls={`faq-answer-${question}`}
    >
      <div className="flex justify-between items-center">
        <h3 className="text-2xl font-bold text-text-primary">{question}</h3>
        <motion.div
          animate={{ rotate: isOpen ? 180 : 0 }}
          transition={{ duration: 0.3 }}
        >
          <ChevronDown className="text-accent-primary" aria-hidden="true" />
        </motion.div>
      </div>
      <AnimatePresence>
        {isOpen && (
          <motion.div
            id={`faq-answer-${question}`}
            initial={{ opacity: 0, height: 0 }}
            animate={{ opacity: 1, height: 'auto' }}
            exit={{ opacity: 0, height: 0 }}
            transition={{ duration: 0.4, ease: [0.42, 0, 0.58, 1] }}
            className="mt-4 overflow-hidden text-lg text-text-secondary leading-relaxed"
          >
            <p>{answer}</p>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

const FAQ = () => (
  <section id="faq" className="py-24 bg-bg-primary w-full">
    <div className="max-w-6xl mx-auto px-6">
      <h2 className="text-3xl font-extrabold text-center mb-16 text-accent-primary tracking-tight">
        Frequently Asked Questions
      </h2>
      <div className="space-y-6">
        <FAQItem
          question="How does the AI model handle different programming languages?"
          answer="Our AI model is trained on a wide variety of low-level programming languages and can adapt to the specific syntax and idioms of each. It supports C, C++, Assembly, and more."
        />
        <FAQItem
          question="Is my code safe and private when using this service?"
          answer="Absolutely. We use Oblivious HTTP to ensure complete anonymity and encryption of all requests and responses. Your code and queries are never stored or accessible to anyone, including us."
        />
        <FAQItem
          question="Can I integrate this AI into my existing development environment?"
          answer="Yes, we plan to provide plugins for popular IDEs. Additionally, we offer an API for custom integrations into your workflow."
        />
      </div>
    </div>
  </section>
);

export default FAQ;
